
































































@import "@/assets/css/project-variables";

.element-scale-config {
  padding: 2px 5px;
  cursor: pointer;

  &.editable {
    border: $--editable-border;
    border-radius: $--border-radius-base;
  }

  .card-view-divider {
    margin:0;
  }
}
